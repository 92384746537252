import React, { useEffect, useRef, useState } from "react"
import Flickity from "react-flickity-component"
import "flickity/dist/flickity.min.css"
import { Link, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"


//component
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import LiveChat from "../../components/Home/livechat";
import TestimonialsText from "../../components/Home/testimonials-text"

// img
import FlowBannerimg from "../../../images/uploads/Flowlab/flow-banner-img.png"
import FlowLogo from "../../../images/uploads/Flowlab/flow-logo.png"
import FlowLogoWhite from "../../../images/uploads/Flowlab/flow-logo-white.png"
import FlowintImg from "../../../images/uploads/Flowlab/flow-intr-img.png"
import FlowintImg2 from "../../../images/uploads/Flowlab/flow-intr-img-2.png"
import FlowRoundAni from "../../../images/uploads/Flowlab/flow-round-ani.svg"
import FlowChallange1 from "../../../images/uploads/Flowlab/flow-challange-1.png"
import FlowChallange2 from "../../../images/uploads/Flowlab/flow-challange-2.png"
import FlowSolutionImg1 from "../../../images/uploads/Flowlab/flow-solution-img-1.png"
import FlowSolutionImg2 from "../../../images/uploads/Flowlab/flow-solution-img-2.png"
import FlowSolutionImg3 from "../../../images/uploads/Flowlab/flow-solution-img-3.png"
import flowTypoImg from "../../../images/uploads/Flowlab/flow-typo-img.png"
import flowTypoDarkimg from "../../../images/uploads/Flowlab/flow-typo-dark-img.png"
import flows1 from "../../../images/uploads/Flowlab/flows1.png"
import flows2 from "../../../images/uploads/Flowlab/flows2.png"
import flows3 from "../../../images/uploads/Flowlab/flows3.png"
import flows4 from "../../../images/uploads/Flowlab/flows4.png"
import flows5 from "../../../images/uploads/Flowlab/flows5.png"
import flows6 from "../../../images/uploads/Flowlab/flows6.png"
import flows7 from "../../../images/uploads/Flowlab/flows7.png"
import flows8 from "../../../images/uploads/Flowlab/flows8.png"
import flows9 from "../../../images/uploads/Flowlab/flows9.png"
import flows10 from "../../../images/uploads/Flowlab/flows10.png"
import flows11 from "../../../images/uploads/Flowlab/flows11.png"
import flows12 from "../../../images/uploads/Flowlab/flows12.png"
import flows13 from "../../../images/uploads/Flowlab/flows13.png"

import addcs1 from "../../../images/uploads/decodeup-card-additional-case-study.png"
import addcs2 from "../../../images/uploads/decodeup-wacom-additional-case-study.png"

const FlobLab = props => {
  useEffect(() => {
    document.body.classList.add("flow-body-class")
  }, [])

  const SEOdata = props.data.pageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.pageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.pageData.edges[0].node.frontmatter?.Hreflang


  const [margin, setMargin] = useState()
  const flRef = useRef()

  useEffect(() => {
    let box = document.querySelector(".container")
    let width = box.offsetWidth
    let innerw = window.innerWidth
    let paddingApplied = innerw - width

    setMargin(paddingApplied)
  })

  const options = {
    // contain: true,
    // wrapAround: true,
    // initialIndex: 2,
    // pageDots: false,
    // autoPlay: false,
    // prevNextButtons: false,
    // adaptiveHeight: true,
    lazyLoad: true,
    prevNextButtons: true,
    cellAlign: "left",
    contain: true,
    freeScroll: true,
    pageDots: false,
    percentPosition: true,
    adaptiveHeight: true,
  }

  const onMouseEnter = () => {
    flRef.current.flkty.player.play()
  }
  const onMouseLeave = () => {
    flRef.current.flkty.player.stop()
  }
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "Flowlab"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner  section */}
        <section className="h-full  xl:h-screen case-draft-section">
          <div className="h-full container mx-auto  ">
            <div className="pb-20 pt-1-63 md:py-2-00 lg:pt-64 h-full flex flex-col justify-center">
              <div className="mb-6-6 lg:mb-50 w-full  case-study-project-heading ">
                <h4 className="pr-5 lg:pl-0 mb-1-2 lg:mb-2-2 font-poppings-normal text-p3 lg:text-p2 text-term-primary">
                  Your space, your way
                </h4>
                <h1 className="mb-3 lg:mb-5 font-poppings-bold text-h2 lg:text-h0 xl:text-h1 text-term-primary">
                  Personalized Virtual Interior Design Lab
                </h1>
                <p className="w-full lg:w-11/20  font-worksans-normal text-p4  md:text-h5 lg:text-p2 text-term-primary">
                  Flow Lab is your best friend for all things interior design.
                  Selecting and placing furniture in your home is not easy and
                  honestly can cause a bit of stress to the average homeowner.
                  With Flow Lab, users receive personalized guidance and a
                  custom plan for their living space. They have the freedom to
                  choose furniture items based on their vision. No more paying
                  money for chairs or end tables you'll never use or grow to
                  hate later. Craft the space you need one item at a time
                </p>
              </div>

              <div className="project-details-draft">
                <div className="flex flex-wrap lg:flex-nowrap">
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      CLIENT
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Matin
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      COUNTRY
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Netherlands
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className="md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      SERVICES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Development, SEO, Maintenance and Support, UI/UX Design,
                      Product management, Hosting and Maintenance
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      TECHNOLOGIES USED
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Wordpress, JS
                    </p>
                  </div>
                  <div className="w-full lg:w-auto mb-5 mr-6-6 xl:mr-66 xl:max-w-xs">
                    <h5 className=" md:pb-2 uppercase text-cyan font-poppings-bold text-h6">
                      INDUSTRIES
                    </h5>
                    <p className="text-term-primary font-worksans-normal text-p3 md:text-p2 ">
                      Business to Consumer
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Banner section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <img
            className="mx-auto"
            src={FlowBannerimg}
            alt="Flow lab Decodeup Technologies"
          />
          <div className="container mx-auto">
            <div className="relative">
              <div className="py-6 mms:px-6 md:p-8 max-w-5-20 mms:absolute -bottom-32 right-0 mms:border border-term-primary bg-background-primary">
                <div className="mb-4 md:mb-8 flow-logo-img">
                  <img
                    className="flow-logo"
                    src={FlowLogo}
                    alt="Flowlab Decodeup Technologies"
                  />
                  <img
                    className="flow-logo-white"
                    src={FlowLogoWhite}
                    alt="Flowlab Decodeup Technologies"
                  />
                </div>
                <div className="flex flex-col gap-5">
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2 ">
                    This site is not only gorgeous, it works easily thanks to a
                    high-powered coding system on the backend.
                  </p>
                  <p className="text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2 ">
                    The founders of Flow Lab chose DecodeUp because they know
                    amazing design and simple functionality is what we do best.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Intro Section  */}
        <section className="mms:pt-24 sm:pt-1-44 md:pt-24 mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="xl:pl-8 flex flex-wrap md:flex-nowrap md:items-center lg:items-start justify-between gap-y-5 md:gap-10 2xl:gap-28">
              <div className="order-2 md:order-1 pt-3 w-full md:w-1/2  2xl:w-full lg:max-w-480">
                <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  The home of your dreams hanks to you
                </h3>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  With the explosion of technology, everything we do can be
                  launched at the touch of a button, including how we socialize,
                  shop, and learn.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  Every day innovators are finding new ways to improve daily life and asking the important question: if this the only way to do this? Building this website was about redefining home decor.
                </p>
                <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                  These are the questions that excite us, and our team was eager
                  to find an answer.
                </p>
              </div>
              <div className="order-1 md:order-2 w-full md:w-1/2 lg:w-1/2 lg:max-w-6-56 relative">
                <div className="mb-4 2md:mb-0 lg:absolute top-16 right-8">
                  <h3 class="md:hidden xl:block text-rp1 lg:text-h3 font-poppings-bold text-term-primary">
                    Meetings With Client
                  </h3>
                </div>
                <div className="w-auto hidden 2md:block">
                  <img src={FlowintImg} alt="Flowlab Decodeup Technologies" />
                </div>
                <div className="2md:absolute w-full md:w-auto right-0 bottom-0 md:left-40 md:top-1-76 2md:border-t-16 2md:border-l-16 border-tone-borderWhite">
                  <img
                    className="w-full h-full xl:h-auto object-cover"
                    src={FlowintImg2}
                    alt="Flowlab Decodeup Technologies"
                  />
                </div>
              </div>
            </div>
            {/* animation section */}
            <div className="xl:mt-48 lg:pl-8 pt-12 lg:pt-20 flex flex-wrap sm:flex-nowrap justify-center sm:justify-start items-center gap-10 lg:gap-16">
              <div className="w-1/2 h-1/2 md:w-1/3 md:h-1/3 lg:w-full lg:h-full max-w-240 max-h-240">
                <img
                  className="flow-animate-spin animate-spin"
                  src={FlowRoundAni}
                  alt="Flowlab Decodeup Technologies"
                />
              </div>
              <div className="w-full max-w-8-00">
                <h3 class="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-center sm:text-left text-term-primary">
                  It's not every day that we get to tackle a topic like home
                  decor. In this case study, you will see some of the hurdles we
                  ran into and how our team opted to take the road less traveled
                  to pull off a difficult task.
                </h3>
              </div>
            </div>
          </div>
        </section>

        {/* challange section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="px-0 xl:px-34">
              <div className="mb-4 lg:mb-6-6">
                <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                  The Challenges
                </h2>
              </div>
              <div className="grid md:grid-cols-2 gap-y-5 md:gap-10 lg:gap-20 xl:gap-28 font-worksans-normal text-p4 lg:text-p3 xl:text-p2 text-term-primary">
                <div className="flex flex-col gap-y-5">
                  <p>
                    The team at Flow Lab chose to work with us for our
                    reputation and commitment to the client. We treat every
                    working relationship with the utmost respect and our full
                    attention. Whether a project lasts a week or a year, we
                    devote every ounce of energy to creating the best product
                    for you.
                  </p>
                  <p>
                    This is one of the things we discuss with Flow Lab in our
                    initial meetings. How would we translate their current
                    programs and future goals into something dynamic that exists
                    online?
                  </p>
                </div>
                <div className="flex flex-col gap-y-5">
                  <p>
                    We first evaluated their current state. Flow Lab has
                    physical retails stores that shoe off what it means to
                    design a flow and theme for a room. They use styling setups
                    so consumers can walk through and see the design elements
                    for themselves.
                  </p>
                  <p>
                    This feel needed to be recreated virtually so the same
                    design tips and advice they share in-person can be shared
                    digitally.
                  </p>
                </div>
              </div>
              <div className="mt-12">
                <h3 class="mb-6-6 md:pr-10 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                  What began as a short term project quickly turned into a
                  long-term one after the Flow Lab team saw the impressive
                  solutions DecodeUp offered
                </h3>
              </div>
            </div>
          </div>
        </section>

        {/* challange section image */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="relative w-full h-full flex items-center">
              <div className="hidden lg:block w-full h-full lg:w-2/29 xl:w-full max-w-3-84 max-h-6-72 xl:absolute top-1/2 left-0 xl:-translate-y-1/2 border-t-16 border-r-16 border-b-16 border-tone-borderWhite">
                <img
                  className="w-full h-full object-cover"
                  src={FlowChallange2}
                  alt="Flowlab Decodeup Technologies"
                />
              </div>
              <div className="ml-auto w-full h-full lg:w-7/10 xl:w-full max-w-10-56 max-h-8-80">
                <img
                  className="w-full h-full object-cover"
                  src={FlowChallange1}
                  alt="Flowlab Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* solution section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="mb-4 lg:mb-5">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Solutions
              </h2>
            </div>
            <div className="pt-6 md:pt-10 flex flex-wrap md:flex-nowrap justify-between md:gap-8 bg-background-primary">
              <div className="mb-4 lg:mb-0 xl:pt-24 w-full lg:w-2/5 lg:max-w-4-64 flex gap-3 lg:gap-6">
                <div className="flex-cnt w-8 h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 rounded-full flex items-center justify-center">
                  <span className="text-rp1 lg:text-h3 font-poppings-bold text-term-primary">
                    1
                  </span>
                </div>
                <div className="flex-cnt-details">
                  <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Providing direction to your design goals
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    One of the solutions we crafted for Flow Lab was a Label
                    system that showcases styling options and corresponding
                    furniture pieces to help users envision their space and
                    flow. This provided the digital and personal direction they
                    need to pick the right items for their home.
                  </p>
                </div>
              </div>
              <div className="mb-8 md:mb-0 w-full h-full lg:w-3/5 xl:w-full max-w-7-36 max-h-6-08">
                <img
                  className="w-full h-full object-cover"
                  src={FlowSolutionImg1}
                  alt="Flowlab Decodeup Technologies"
                />
              </div>
            </div>
            <div className="pt-6 md:pt-10 flex flex-wrap md:flex-nowrap justify-between md:gap-8 bg-background-primary">
              <div className="mb-4 lg:mb-0 xl:pt-24 w-full lg:w-2/5 lg:max-w-4-64 flex gap-3 lg:gap-6">
                <div className="flex-cnt w-8 h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 rounded-full flex items-center justify-center">
                  <span className="text-rp1 lg:text-h3 font-poppings-bold text-term-primary">
                    2
                  </span>
                </div>
                <div className="flex-cnt-details">
                  <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    Solutions made just for you
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    We never produce cookie-cutter solutions or drag-and-drop
                    answers. Each client is given well-developed and personal
                    solutions for their needs and audience. That's why we treat
                    our discovery calls so seriously--it's crucial to our
                    success.
                  </p>
                </div>
              </div>
              <div className="mb-8 md:mb-0 w-full h-full lg:w-3/5 xl:w-full max-w-7-36 max-h-6-08">
                <img
                  className="w-full h-full object-cover"
                  src={FlowSolutionImg2}
                  alt="Flowlab Decodeup Technologies"
                />
              </div>
            </div>

            <div className="pt-6 md:pt-10 flex flex-wrap md:flex-nowrap justify-between md:gap-8 bg-background-primary">
              <div className="mb-4 lg:mb-0 xl:pt-24 w-full lg:w-2/5 lg:max-w-4-64 flex gap-3 lg:gap-6">
                <div className="flex-cnt w-8 h-8 lg:w-10 lg:h-10 xl:w-12 xl:h-12 rounded-full flex items-center justify-center">
                  <span className="text-rp1 lg:text-h3 font-poppings-bold text-term-primary">
                    3
                  </span>
                </div>
                <div className="flex-cnt-details">
                  <h3 class="mb-2 lg:mb-4 xl:mb-6-6 text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                    A website that checks all the boxes
                  </h3>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    This client was looking for a digital team that could meet
                    all of their needs, including:
                  </p>
                  <ul className="mb-5 flex flex-col gap-3 lg:gap-5 list-none flow-solution-ul text-term-primary font-worksans-normal text-p4 lg:text-p3 xl:text-p2">
                    <li className="ml-6 lg:ml-10 relative">
                      A sophisticated design
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Simple interfaces on the front end
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      Powerful engineering on the backend
                    </li>
                    <li className="ml-6 lg:ml-10 relative">
                      A site that offered the personal attention they provide
                      in-store
                    </li>
                  </ul>
                  <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                    They knew what they needed and we were happy to check all
                    the boxes on their wishlist.
                  </p>
                </div>
              </div>
              <div className="w-full h-full xl:w-full lg:w-3/5 max-w-7-36 max-h-6-08">
                <img
                  className="w-full h-full object-cover"
                  src={FlowSolutionImg3}
                  alt="Flowlab Decodeup Technologies"
                />
              </div>
            </div>
          </div>
        </section>

        {/* typographuy section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="mb-12">
              <h2 className="font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                Typeface & Colors
              </h2>
            </div>
            <div className="flex justify-between flex-wrap">
              <div className="w-2/5 md:w-auto">
                <img
                  className="flow-typo-light"
                  src={flowTypoImg}
                  alt="Flowlab Decodeup Technologies"
                />
                <img
                  className="flow-typo-dark"
                  src={flowTypoDarkimg}
                  alt="Flowlab Decodeup Technologies"
                />
              </div>
              <div className="mt-6 md;mt-0 w-full max-w-9-12">
                <div className="mb-6 md:mb-16 flex flex-wrap gap-4 md:gap-16">
                  <div className="2md:w-5/20 lg:w-auto flow-arial">
                    <h4 className="text-term-primary">Arial</h4>
                    <h5 className="text-term-primary opacity-70">
                      ABCDEFGHIJKLMNOPQRSTUVWXYZ
                    </h5>
                    <h6 className="text-term-primary opacity-70">
                      abcdefghijklmnopqrstuvwxyz
                    </h6>
                  </div>
                  <div className="2md:w-5/20 lg:w-auto flow-lucida">
                    <h4 className="text-term-primary">Lucida Sans Unicode</h4>
                    <h5 className="text-term-primary opacity-70">
                      ABCDEFGHIJKLMNOPQRSTUVWXYZ
                    </h5>
                    <h6 className="text-term-primary opacity-70">
                      abcdefghijklmnopqrstuvwxyz
                    </h6>
                  </div>
                </div>
                <div className="w-full flex flex-wrap">
                  <div className="p-4 lg:p-8 w-1/3 flow-colo-box-1">
                    <p className="text-rp1 lg:text-h3 font-poppings-bold">
                      #B0C4B0
                    </p>
                  </div>
                  <div className="p-4 lg:p-8 w-1/3 flow-colo-box-2">
                    <p className="text-rp1 lg:text-h3 font-poppings-bold">
                      #C99D98
                    </p>
                  </div>
                  <div className="p-4 lg:p-8 w-1/3 flow-colo-box-3">
                    <p className="text-rp1 lg:text-h3 font-poppings-bold">
                      #999999
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Result Section  */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container mx-auto">
            <div className="mb-6 w-full lg:w-5/7 lg:pr-16">
              <h2 className="mb-4 lg:mb-6-6 font-poppings-bold text-rh2 lg:text-h0 xl:text-h1 text-term-primary">
                The Result
              </h2>
              <p className="mb-2 lg:mb-3 xl:mb-5 text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Our team was pleased to deliver a beautiful, well-working site
                that could be maintained and updated with ease.
              </p>
              <p className="text-p4 lg:text-p3 xl:text-p2 text-term-primary font-worksans-normal">
                Flow Lab was thrilled at the result and when the client is
                pleased, so are we. Of course, a website is never static, it is
                constantly evolving and like your house needs little
                improvements and upgrades to remain a home.
              </p>
            </div>
            <div className="w-full lg:w-7/10 lg:pr-10">
              <h3 className="text-h4 lg:text-sh3 xl:text-h3 font-poppings-bold text-term-primary">
                How can we make your day today? From a brand new website to an
                improved e-store, DecodeUp will answer the call.
              </h3>
            </div>
          </div>
        </section>

        {/* slider section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40 overflow-hidden">
          <div className="case-study-slider-bg">
            <div className="container container-cor mx-auto">
              <div
                id="myid"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Flickity
                  className={"case-studies-carousel cstm-du-casestudy-slider"}
                  elementType={"div"}
                  options={options}
                  ref={flRef}
                >
                  <img
                    className="cs-carousel-image"
                    src={flows1}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows2}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows3}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows4}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows5}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows6}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows7}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows8}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows9}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows10}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows3}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows11}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows12}
                    alt="flows Decodeup Technologies"
                  />
                  <img
                    className="cs-carousel-image"
                    src={flows13}
                    alt="flows Decodeup Technologies"
                  />
                  {/* {info.screenshots_for_carousel.map(
                        ({ image: img }, ss) => (
                          <img
                            key={ss}
                            src={getSrc(img)}
                            className="cs-carousel-image"
                            alt="decodeup case study"
                          />
                        )
                      )} */}
                </Flickity>
              </div>
            </div>
          </div>
        </section>

        {/* client testimonials carousel section */}
        <section className="mb-60 md:mb-20 xl:mb-1-40">
          <div className="container container-cor mx-auto">
            <div className="pr-2 pl-5-3 lg:pl-60 lg:pr-60 xl:px-90 xl:pl-1-41 xl:pr-1-41 2xl:pl-1-86 2xl:pr-1-87">
              <TestimonialsText />
            </div>
          </div>
        </section>

        {/* additional case studies section */}

        <section className="pb-50 md:pt-50 lg:pt-62 xl:pt-0 lg:pb-1-20">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-36 text-center">
              <h3 className="d-head-title text-rp1  lg:text-h3 font-poppings-bold text-term-primary">
                Additional Case Studies
              </h3>
            </div>
            <div className="h-full flex flex-wrap">
              <div className="px-4-1 pt-36 w-full sm:w-1/2 text-center bg-cyan">
                <Link
                  to="/case-study/food-company-ecommerce-store"
                  className="h-full flex items-center justify-between flex-col text-center addi-case-links"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Food Company eCommerce Store
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=""
                      src={addcs1}
                      alt="Cordialsa Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
              <div className=" pt-36 w-full sm:w-1/2 text-center bg-background-case">
                <Link
                  // to={post.fields.slug}
                  to="/case-study/online-store-for-tablets-stylus"
                  className="h-full flex items-center justify-between flex-col text-center"
                >
                  <p className="pb-2-2 lg:pb-4 text-rp1 md:text-p1 text-white font-worksans-bold">
                    Online Store for Tablets and Stylus Products  
                  </p>
                  <div className="h-full xl:h-3-20 mx-auto overflow-hidden">
                    <img
                      className=" w-full h-full object-cover"
                      src={addcs2}
                      alt="WacomeStore Decodeup Technologies"
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  )
}

export default FlobLab

export const pageQuery = graphql`
query flowlab {
  pageData: allMarkdownRemark(
    filter: { fileAbsolutePath: { regex: "/case-studies/virtual-interior-design-lab.md/" } }
  ) {
    edges {
      node {
        frontmatter {
          seo_metadata {
            seo_title
            seo_keyword
            seo_description
            image_format
            feature_image_height
            img_width
            facebook_url
            twitter_page_username
            twitter_image_alt_tag
            twitter_estimated_reading_lable
            twitter_estimated_reading_time
            page_publish_date
            page_last_modified_time
            seo_Image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 1200)
              }
            }
            seo_Single_Image
          }
          Schema {
            sitelinks_schema
            breadcrumb_schema
            logo_schema
            organization_schema
            aggregate_rating_schema
            local_business_schema
            site_navigation_schema
            speakable_specification_schema
          }
          Hreflang {
            hreflang_1
            hreflang_2
            hreflang_3
            hreflang_4
          }
        }
      }
    }
  }
}

`;
